import { createContext, PropsWithChildren, useContext } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  Grid,
  Avatar,
  Skeleton,
  Tooltip,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ILearnAndEarnOffer } from "./types";
import { useLearnAndEarnSelectedOffer } from "./Selected";
import { Check } from "@mui/icons-material";
import { useTheme } from "@mui/styles";

interface ILearnAndEarnOfferProps {
  loading?: boolean;
  offer: ILearnAndEarnOffer;
}

const LearnAndEarnOfferContext = createContext<null | ILearnAndEarnOfferProps>(
  null
);

const LearnAndEarnOfferProvider = ({
  loading,
  offer,
  children,
}: PropsWithChildren<ILearnAndEarnOfferProps>) => {
  return (
    <LearnAndEarnOfferContext.Provider value={{ loading, offer }}>
      {children}
    </LearnAndEarnOfferContext.Provider>
  );
};

const useLearnAndEarnOffer = () => {
  const context = useContext(LearnAndEarnOfferContext);

  if (!context)
    throw new Error(
      "useLearnAndEarnOffer must be used within a LearnAndEarnOfferProvider"
    );

  return context;
};

const LearnAndEarnOfferImage = () => {
  const { loading, offer } = useLearnAndEarnOffer();
  const style = {
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderRadius: 0,
    borderTopLeftRadius: "13px",
    borderBottomLeftRadius: "13px",
  };
  return (
    <Box style={{ width: "36%" }}>
      {loading ? (
        <Skeleton variant="rectangular" sx={style} />
      ) : (
        <Box
          sx={{
            ...style,
            backgroundImage: `url(${offer.imageUrl})`,
          }}
        />
      )}
    </Box>
  );
};

const LearnAndEarnHeader = () => {
  const { loading, offer } = useLearnAndEarnOffer();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "top",
        justifyContent: "space-between",
        mb: 1,
      }}
    >
      {loading ? (
        <Skeleton
          variant="rounded"
          style={{ width: 108, height: 36, marginRight: 4 }}
        />
      ) : (
        <img
          src={offer.logoUrl}
          alt={offer.title}
          style={{ height: offer.logoHeight || 36, marginRight: 4 }}
        />
      )}

      {loading ? null : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <AccessTimeIcon sx={{ mr: 0.5, color: "text.secondary" }} />
          <Typography variant="caption" color="text.secondary" style={{ whiteSpace: 'nowrap' }}>
            {Math.floor(offer.estimatedTimeSeconds / 60)} mins
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const LearnAndEarnContent = () => {
  const { loading, offer } = useLearnAndEarnOffer();
  return (
    <Typography variant="body2" sx={{ mb: 2, flex: 1, fontSize: "clamp(0.7rem, 0.629rem + 0.19vw, 0.8rem)" }}>
      {loading ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </>
      ) : (
        offer.description
      )}
    </Typography>
  );
};

const LearnAndEarnFooter = () => {
  const { loading, offer } = useLearnAndEarnOffer();

  const { setSelected, setOpen } = useLearnAndEarnSelectedOffer();

  const history = offer.history?.[0]
  const attempts = (history?.attempts ?? 0)
  const maxAttempts = (history?.granted ?? 0) + (offer?.maxAttempts ?? 1)
  const remainingAttempts = maxAttempts - attempts
  const rewarded = history?.sentDrop ?? false

  const tooltipText = rewarded ? 'You have already completed this offer' : remainingAttempts <= 0 ? 'You have no more attempts left' : `Complete this offer to earn rewards. You have ${remainingAttempts} attempt${remainingAttempts === 1 ? '' : 's'} left.`

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gridGap: 4
      }}
    >
      <Typography variant="subtitle2" fontWeight="bold" style={{
        fontSize: 'clamp(0.8rem, 0.746rem + 0.143vw, 0.875rem)',
        whiteSpace: 'nowrap'
      }}>
        {loading ? <span>&nbsp;</span> : `Earn ${offer.rewardAmount} ${offer.rewardType}`}
      </Typography>

      <Tooltip title={<Typography>{tooltipText}</Typography>}>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={loading || rewarded || remainingAttempts <= 0}
            style={{
              fontSize: 'clamp(0.7rem, 0.575rem + 0.333vw, 0.875rem)',
              display: 'flex',
              alignItems: 'center',
              gridGap: 4
            }}
            onClick={() => {
              setSelected(offer);
              setOpen('content');
            }}
          >
            {loading ? <span>&nbsp;</span> : rewarded ? <><Check /> Completed</> : <strong style={{ textTransform: 'none', color: 'white' }}>{offer.buttonText || "Learn & Earn"}</strong>}
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

export const LearnAndEarnOffer = ({ loading, offer }: ILearnAndEarnOfferProps) => {
  const theme: any = useTheme()
  return (
    <LearnAndEarnOfferProvider loading={loading} offer={offer}>
      <Paper elevation={3} style={{ overflow: "hidden", borderRadius: 16 }}>
        <Box style={{ padding: 3, display: "flex" }}>
          {/* Left side with image */}
          <LearnAndEarnOfferImage />

          {/* Right side with content */}
          <Box style={{ 
            flex: 1, 
            padding: `clamp(0.25rem, 0.071rem + 0.476vw, 0.5rem)`, 
            paddingLeft: 'clamp(0.75rem, -0.321rem + 2.857vw, 2.25rem)', 
            paddingRight: 'clamp(0.5rem, -0.214rem + 1.905vw, 1.5rem)',
            backgroundColor: theme.palette.background.default,
            borderRadius: 0,
            borderTopRightRadius: "13px",
            borderBottomRightRadius: "13px",
          }}>
            <LearnAndEarnHeader />
            <LearnAndEarnContent />
            <LearnAndEarnFooter />
          </Box>
        </Box>
      </Paper>
    </LearnAndEarnOfferProvider>
  );
};
