import { List } from '@mui/material'

import HistoryListItem from './HistoryListItem'
import { PickaxeIcon } from '../Pickaxes/Icon'
import { HelmetIcon } from '../Helmets/Icon'
import ProfileIcon from '@mui/icons-material/Person'
import InfographicIcon from '@mui/icons-material/Assignment'
import { BootsIcon } from '../Boots/Icon'

const mlIconStyle = { height: 16, width: 16 }
const NftLinks = ({ pl }: { pl?: number }) => {
  return (
    <List disablePadding component="div" sx={{ width: '100%' }}>
      <HistoryListItem to="/profiles" title="Profiles" imgSrc={<ProfileIcon sx={{...mlIconStyle, transform: 'scale(1.4)'}} />} sx={{ pl }} />
      <HistoryListItem to="/pickaxes" title="Pickaxes" imgSrc={<PickaxeIcon />} sx={{ pl }} />
      <HistoryListItem to="/helmets" title="Mining Helmets" imgSrc={<HelmetIcon />} sx={{ pl }} />
      <HistoryListItem to="/boots" title="Mining Boots" imgSrc={<BootsIcon />} sx={{ pl }} />
      <HistoryListItem to="/infographics" title="Stats Infographics" imgSrc={<InfographicIcon sx={{...mlIconStyle, transform: 'scale(1.2)'}} />} sx={{ pl }} />
    </List>
  )
}

export default NftLinks
