import { Typography } from '@mui/material'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FlexGrowRow } from '../../components/Flex'
import { AccountPage, AudioAdsPage, AuthPage, DownloadCoinPage, EarnPage, InvoicesPage, InvoicesPastDuePage, TestPage, VideoAdsPage } from '../../pages'
import {
  AccountDelete,
  AppLink,
  AuthActions,
  BackgroundRewardsPage,
  Badges,
  BigGeominesPage,
  ChangeEmail,
  Faq,
  FourOhFour,
  FreeTrialTest,
  GeoclaimsPage,
  HodlPage,
  GeodropsPage,
  GeominesPage,
  Identity,
  Invite,
  Master,
  Opinion,
  PickaxeMint,
  Profiles,
  Pickaxes,
  HelmetMint,
  Helmets,
  BootMint,
  Boots,
  Plans,
  RedeemDetailsPage,
  RedeemPage,
  Referrals,
  Referrer,
  Refund,
  RewardedScansPage,
  RewardedVisitsPage,
  Settings,
  Success,
  SurveysPage,
  SweepstakesHistoryPage,
  ThankYou,
  VerifyDevice,
  VerifyPage,
  VerifyRedeem,
  CoinStatSquareCard,
  CoinStatPortraitCard,
  CoinStatLandscapeCard,
  CoinStatPortraitBlank,
  ProfileDetails,
  ProfileCheckout,
  ProfileCheckoutSuccess,
  OfferUnavailable,
  LearnAndEarn,
  LimitedReleaseDrops,
  Infographics
} from '../../routes'
import { ExternalRedirect, InvitedAdminRoute, Private, PrivateEmail, PrivateToken, RequireReauthentication } from '../../User'
import { RedirectWithQuery } from '../../User/components/RedirectWithQuery'
import { useTracking } from '../../utils'

const PagesRouter = () => {
  useTracking()
  return (
    <Routes>
      <Route path="/faq" element={<Faq />} />
      <Route path="/terms" element={<ExternalRedirect to="https://coinapp.co/legal/terms" />} />
      <Route path="/privacy" element={<ExternalRedirect to="https://coinapp.co/legal/privacy" />} />
      <Route path="/sweepstakes-terms" element={<ExternalRedirect to="https://coinapp.co/legal/sweepstakes-terms" />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/profile/:username" element={<ProfileDetails />} />
      <Route path="/profile/:username/checkout" element={<ProfileCheckout />} />
      <Route path="/profile/:username/checkout/success" element={<ProfileCheckoutSuccess />} />

      <Route path="/freetrial" element={<DownloadCoinPage />} />
      {process.env.NODE_ENV === 'development' ? <Route path="/freetrial/test" element={<FreeTrialTest />} /> : null}
      <Route path="/devices" element={<DownloadCoinPage />} />
      <Route path="/applink/:deeplink" element={<AppLink />} />
      <Route path="/applink/:deeplink/:one" element={<AppLink />} />
      <Route path="/applink/:deeplink/:one/:twq" element={<AppLink />} />
      <Route path="/success" element={<Success />} />

      <Route
        path="/auth"
        element={
          <>
            <AuthPage />
            <FlexGrowRow />
          </>
        }
      />
      <Route path="/invite/:id" element={<Invite />} />
      <Route path="/__/auth/action" element={<AuthActions />} />
      <Route path="/auth/action" element={<AuthActions />} />
      <Route
        path="/account"
        element={
          <Private to="/auth">
            <AccountPage />
          </Private>
        }
      />
      {/* <Private
                path="/account_v4"
                to="/auth"
                exact
                component={AccountV4}
              /> */}
      <Route
        path="/invoices"
        element={
          <Private to="/auth">
            <InvoicesPage />
          </Private>
        }
      />
      <Route
        path="/badges"
        element={
          <Private to="/auth">
            <Badges />
          </Private>
        }
      />
      <Route
        path="/profiles"
        element={
          <Private to="/auth">
            <Suspense
              fallback={
                <div style={{ flex: 1 }}>
                  <Typography textAlign="center">Loading profiles...</Typography>
                </div>
              }
            >
              <Profiles />
            </Suspense>
          </Private>
        }
      />
      <Route
        path="/infographics"
        element={
          <Private to="/auth">
            <Suspense
              fallback={
                <div style={{ flex: 1 }}>
                  <Typography textAlign="center">Loading infographics...</Typography>
                </div>
              }
            >
              <Infographics />
            </Suspense>
          </Private>
        }
      />
      <Route
        path="/pickaxes"
        element={
          <Private to="/auth">
            <Suspense
              fallback={
                <div style={{ flex: 1 }}>
                  <Typography textAlign="center">Loading pickaxes...</Typography>
                </div>
              }
            >
              <Pickaxes />
            </Suspense>
          </Private>
        }
      />
      <Route
        path="/pickaxes/mint"
        element={
          <Private to="/auth">
            <Suspense
              fallback={
                <div style={{ flex: 1 }}>
                  <Typography textAlign="center">Loading pickaxes...</Typography>
                </div>
              }
            >
              <PickaxeMint />
            </Suspense>
          </Private>
        }
      />
      <Route
        path="/helmets"
        element={
          <Private to="/auth">
            <Suspense
              fallback={
                <div style={{ flex: 1 }}>
                  <Typography textAlign="center">Loading helmets...</Typography>
                </div>
              }
            >
              <Helmets />
            </Suspense>
          </Private>
        }
      />
      <Route
        path="/helmets/mint"
        element={
          <Private to="/auth">
            <Suspense
              fallback={
                <div style={{ flex: 1 }}>
                  <Typography textAlign="center">Loading helmets...</Typography>
                </div>
              }
            >
              <HelmetMint />
            </Suspense>
          </Private>
        }
      />
      <Route
        path="/boots"
        element={
          <Private to="/auth">
            <Suspense
              fallback={
                <div style={{ flex: 1 }}>
                  <Typography textAlign="center">Loading boots...</Typography>
                </div>
              }
            >
              <Boots />
            </Suspense>
          </Private>
        }
      />
      <Route
        path="/boots/mint"
        element={
          <Private to="/auth">
            <Suspense
              fallback={
                <div style={{ flex: 1 }}>
                  <Typography textAlign="center">Loading boots...</Typography>
                </div>
              }
            >
              <BootMint />
            </Suspense>
          </Private>
        }
      />
      <Route
        path="/invoices-past-due"
        element={
          <Private to="/auth">
            <InvoicesPastDuePage />
          </Private>
        }
      />
      <Route
        path="/subscriptions/:subscriptionId/invoices"
        element={
          <Private to="/auth">
            <InvoicesPage />
          </Private>
        }
      />
      <Route
        path="/account/delete"
        element={
          <Private to="/auth">
            <AccountDelete />
          </Private>
        }
      />
      <Route
        path="/offer/unavailable"
        element={<OfferUnavailable />}
      />
      <Route
        path="/learn"
        element={<PrivateToken to="/auth"><LearnAndEarn /></PrivateToken>}
      />
      <Route
        path="/limited/release/:id"
        element={<LimitedReleaseDrops />}
      />
      <Route
        path="/verify"
        element={
          <Private to="/auth">
            <VerifyPage />
          </Private>
        }
      />
      <Route
        path="/hodl"
        element={
          <Private to="/auth">
            <VerifyPage />
          </Private>
        }
      />
      <Route path="/device-verification/verify" element={<VerifyDevice />} />
      <Route path="/redeem/verify" element={<VerifyRedeem />} />
      <Route path="/sweepstakes" element={<RedeemPage initialTab="sweepstakes" />} />
      <Route path="/sweepstakes/history" element={<SweepstakesHistoryPage />} />
      <Route
        path="/withdraw"
        element={
          <Private to="/auth">
            <RedeemPage />
          </Private>
        }
      />
      <Route
        path="/redeem/details"
        element={
          <Private to="/auth">
            <RedeemDetailsPage />
          </Private>
        }
      />
      <Route
        path="/redeem"
        element={
          <PrivateEmail to="/auth">
            <RedeemPage />
          </PrivateEmail>
        }
      />
      <Route
        path="/redeem/*"
        element={
          <PrivateEmail to="/auth">
            <RedeemPage />
          </PrivateEmail>
        }
      />
      <Route
        path="/redeem-details/*"
        element={
          <PrivateEmail to="/auth">
            <RedeemPage />
          </PrivateEmail>
        }
      />
      <Route
        path="/account-email/verify"
        element={
          <PrivateEmail to="/auth">
            <RedeemPage />
          </PrivateEmail>
        }
      />
      <Route
        path="/change/email/:code"
        element={
          <RequireReauthentication to="/auth">
            <ChangeEmail />
          </RequireReauthentication>
        }
      />
      <Route
        path="/refund/:id"
        element={
          <Private to="/auth">
            <Refund />
          </Private>
        }
      />
      <Route
        path="/identity"
        element={
          <Private to="/auth">
            <Identity />
          </Private>
        }
      />
      <Route
        path="/master"
        element={
          <Private to="/auth">
            <Master />
          </Private>
        }
      />
      {/* <Private
                path="/redeem/:asset"
                to="/auth"
                exact
                component={WithdrawAsset}
              /> */}
      {/* <Private path="/history" to="/auth" component={History} /> */}
      <Route
        path="/geoclaims"
        element={
          <Private to="/auth">
            <GeoclaimsPage />
          </Private>
        }
      />
      <Route
        path="/geomines"
        element={
          <Private to="/auth">
            <GeominesPage />
          </Private>
        }
      />
      <Route
        path="/geodrops"
        element={
          <Private to="/auth">
            <GeodropsPage />
          </Private>
        }
      />
      <Route
        path="/hodl/history"
        element={
          <Private to="/auth">
            <HodlPage />
          </Private>
        }
      />
      <Route
        path="/videoads"
        element={
          <Private to="/auth">
            <VideoAdsPage />
          </Private>
        }
      />
      <Route
        path="/audioads"
        element={
          <Private to="/auth">
            <AudioAdsPage />
          </Private>
        }
      />
      {/* <Route
        path="/bigwins"
        element={
          <Private to="/auth">
            <BigGeominesPage />
          </Private>
        }
      /> */}
      <Route
        path="/background"
        element={
          <Private to="/auth">
            <BackgroundRewardsPage />
          </Private>
        }
      />
      <Route
        path="/referrals"
        element={
          <Private to="/auth">
            <Referrals />
          </Private>
        }
      />
      <Route
        path="/referrer"
        element={
          <Private to="/auth">
            <Referrer />
          </Private>
        }
      />
      <Route
        path="/settings"
        element={
          <Private to="/auth">
            <Settings />
          </Private>
        }
      />
      <Route
        path="/surveys"
        element={
          <Private to="/auth">
            <SurveysPage />
          </Private>
        }
      />
      <Route
        path="/earn"
        element={
          <Private to="/auth">
            <EarnPage />
          </Private>
        }
      />
      <Route
        path="/opinion"
        element={
          <Private to="/auth">
            <Opinion />
          </Private>
        }
      />
      <Route
        path="/rewarded/visits"
        element={
          <Private to="/auth">
            <RewardedVisitsPage />
          </Private>
        }
      />
      <Route
        path="/rewarded/scans"
        element={
          <Private to="/auth">
            <RewardedScansPage />
          </Private>
        }
      />

      <Route path="/stat/card/blank" element={<CoinStatPortraitBlank />} />
      <Route path="/stat/card/:yyyymm" element={<Private to="/auth"><CoinStatPortraitCard /></Private>} />
      <Route path="/stat/card/square/:yyyymm" element={<Private to="/auth"><CoinStatSquareCard /></Private>} />
      <Route path="/stat/card/landscape/:yyyymm" element={<Private to="/auth"><CoinStatLandscapeCard /></Private>} />
      <Route path="/stat/card/landscape/:yyyymm" element={<Private to="/auth"><CoinStatLandscapeCard /></Private>} />
      <Route path="/public/stat/card/blank" element={<CoinStatPortraitBlank />} />
      <Route path="/public/stat/card/:yyyymm" element={<CoinStatPortraitCard />} />
      <Route path="/public/stat/card/square/:yyyymm" element={<CoinStatSquareCard />} />
      <Route path="/public/stat/card/landscape/:yyyymm" element={<CoinStatLandscapeCard />} />
      {/* <Private path="/rewarded/validations" to="/auth" component={RewardedVerificationsPage} /> */}
      {/* <Private path="/rewarded/verifications" to="/auth" component={RewardedVerificationsPage} /> */}
      <Route path="/premium" element={<RedirectWithQuery to="/account?utm_source=app_promo" />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/test" element={<TestPage />} />
      <Route
        path="/"
        element={
          <Private to="/auth">
            <AccountPage />
          </Private>
        }
      />
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  )
}

export default PagesRouter
